import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'; 
import { useLocation, useNavigate } from 'react-router-dom';

//Layout
import PublicationListeVisiteur from '../components/layouts/public/PublicationListeVisiteur' //Visiteur
import PublicationListUtilisateur from '../components/layouts/utilisateur/PublicationListeUtilisateur' //Utilisateur
import ModalPreferencesUtilisateur from '../components/layouts/utilisateur/ModalPreferencesUtilisateur' //Modal de préférence

//Stores
// import useGetListePublicationVisiteur from '../components/store/public/post-listePublication'; //Visiteur - Ancien mode d'affichage des publications
// import useGetListePublicationUtilisateur from '../components/store/utilisateur/post-listePublicationGenerale'; //Utilisateur - Ancien mode d'affichage des publications

import useGetListePublicationSuggestion from '../components/store/public/post-listePublicationSuggestion'; //Viisteur- Suggestions
import useGetListePublicationPreferences from '../components/store/utilisateur/post-listePublicationPreferences'; //Utilisateur- avec/Sans préférences

//Global
import LoadingSpinner from '../components/global/Spinner';
import NotificationBubble from '../components/global/NotificationBubble';

//Asset
import preferenceActive from '../assets/preference-active.png';


const Accueil = () => {

  const location = useLocation();
  const navigate = useNavigate();


  const [publications, setPublications] = useState([]);

  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [asLoadingMore, setAsLoadingMore] = useState(false);
  const [asMorePublication, setAsMorePublication] = useState(true);
  const [numberSkip, setNumberSkip] = useState(0);

  const [testAlgo, setTestAlgo] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false); 
  const token = localStorage.getItem('token'); 

  const [unixTimestampDate, setUnixTimestampDate] = useState(0);
  const [modalPreferencesIsOpen, setModalPreferencesIsOpen] = useState(false);
  var provenance = location.state?.provenance;


  //Ancien mode d'affichage des publications
  // const { 
  //   getListePublicationVisiteur, 
  //   publicationsVisiteur, 
    // loading: loadingVisiteur, 
  //   error: errorVisiteur 
  // } = useGetListePublicationVisiteur(numberSkip);

  //Ancien mode d'affichage des publications
  // const { 
    // getListePublicationUtilisateur, 
    // publicationsUtilisateur, 
    // loading: loadingUtilisateur, 
    // error: errorUtilisateur 
  // } = useGetListePublicationUtilisateur(numberSkip);
  

  const { 
    getListePublicationSuggestion, 
    publicationSuggestion, 
    // loading: loadingPublicationSuggestion, 
    error: errorPublicationSuggestion
  } = useGetListePublicationSuggestion(numberSkip, unixTimestampDate);

  const { 
    getListePublicationPreferences, 
    publicationPreferences, 
    // loading: loadingPublicationPreferences, 
    error: errorPublicationPreferences
  } = useGetListePublicationPreferences(numberSkip, unixTimestampDate);
  

   const scrollToTop = () => {
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

  };
  


  //Chargement initiale - avec définition d'un nuix timestamp
  useEffect(() => {
    
    setUnixTimestampDate(Date.now())
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    
    if(unixTimestampDate > 0)
    {

      const fetchInitialPublications = async () => {
        
        if (token) {
          // await getListePublicationUtilisateur(); //Ancienne méthode d'affichage des publications
          await getListePublicationPreferences();
        } else {
          // await getListePublicationVisiteur(); //Ancienne méthode d'affichage des publications
          await getListePublicationSuggestion();
        }

      };

      fetchInitialPublications();

      console.log(provenance)

      if(provenance === "menu-utilisateur")
      {
        
        setModalPreferencesIsOpen(true);
        navigate("/", {
          replace: true,
          state: {}
        });
      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unixTimestampDate]);





  //Gestion du système de chargement des publications supplémentaires -- Ancienne méthode de chargement des publcation
  // useEffect(() => {

  //   if(isFirstLoading){


  //     if (token) {
  //       if (publicationsUtilisateur && publicationsUtilisateur.length > 0) {
  //         setPublications(publicationsUtilisateur);
  //         setIsFirstLoading(false)
  //       }
  //     } else {
  //       if (publicationsVisiteur && publicationsVisiteur.length > 0) {
  //         setPublications(publicationsVisiteur); 
  //         setIsFirstLoading(false)
  //       }
  //     }

  //   }
  //   else
  //   {

  //     if(asLoadingMore)
  //     {

  //       if (token) {

  //         if (publicationsUtilisateur && publicationsUtilisateur.length > 0) {
  //           setPublications((prev) => [...prev, ...publicationsUtilisateur]);
  //           setAsLoadingMore(false)
  //           setIsLoading(false);
  //         }
  //         else
  //         {
  //           setAsMorePublication(false) //Il n'y à plus de publication à charger
  //           setAsLoadingMore(false)
  //           setIsLoading(false);
  //         }
  
  //       }
  //       else {
  
  //         if (publicationsVisiteur && publicationsVisiteur.length > 0) {
  //           setPublications((prev) => [...prev, ...publicationsVisiteur]);
  //           setAsLoadingMore(false)
  //           setIsLoading(false);
  //         }
  //         else
  //         {
  //           setAsMorePublication(false)//Il n'y à plus de publication à charger
  //           setAsLoadingMore(false)
  //           setIsLoading(false);
  //         }
  
  //       }

  //     }


  //   }
    
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicationsVisiteur, publicationsUtilisateur]);


  


  useEffect(() => {

    if (isFirstLoading && testAlgo === 1) {
      getListePublicationPreferences();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoading, testAlgo]);
  


  const changeUserPreference = () => {
    setIsFirstLoading(true);
    setAsLoadingMore(false); 
    setAsMorePublication(true);
    setNumberSkip(0);
    setTestAlgo(1);
  };


  //Mise en place des publications basé sur l'algorythme
  useEffect(() => {

    if(publicationPreferences)
    {

      if(isFirstLoading){

        
        if (token) {
          if (publicationPreferences && publicationPreferences.length > 0) {
            setPublications(publicationPreferences);
            setIsFirstLoading(false)
            setIsLoading(false);
          }
        } else {
          
          if (publicationSuggestion && publicationSuggestion.length > 0) {
            setPublications(publicationSuggestion);
            setIsFirstLoading(false)
            setIsLoading(false);
          }

        }
  
       
      }
      else
      {

        if(asLoadingMore)
        {

         

          if (token) {

            if (publicationPreferences && publicationPreferences.length > 0) {
              setPublications((prev) => [...prev, ...publicationPreferences]);
              setAsLoadingMore(false)
              setIsLoading(false);
            }
            else
            {
              setAsMorePublication(false) //Il n'y à plus de publication à charger
              setAsLoadingMore(false)
              setIsLoading(false);
            }
    
          }
          else 
          {
    
            if (publicationSuggestion && publicationSuggestion.length > 0) {
              setPublications((prev) => [...prev, ...publicationSuggestion]);
              setAsLoadingMore(false)
              setIsLoading(false);
            }
            else
            {
              setAsMorePublication(false) //Il n'y à plus de publication à charger
              setAsLoadingMore(false)
              setIsLoading(false);
            }
    
          }
          
        }


        


      }

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicationPreferences, publicationSuggestion]);





  //Déclenchement du chargement des publications supplémentaires
  const loadPublications = async () => {

    if (isLoading) return; // Permet d'évitre la duplication de requête
    setIsLoading(true);
    setAsLoadingMore(true)


    try {

      
      if (token) {
        // await getListePublicationUtilisateur(); //Ancienne méthode d'affichage des publications
        await getListePublicationPreferences();
      } 
      else {
        // await getListePublicationVisiteur(); //Ancienne méthode d'affichage des publications
        await getListePublicationSuggestion();
      }
      

    } catch (error) {
      console.error('Error loading publications');
    } 
    
    
  };


  useEffect(() => {
    
    if (numberSkip > 0) {
      
      if(asMorePublication) 
      {    
        loadPublications();
      }

    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberSkip]); 




  
  useEffect(() => {

    if(asMorePublication) 
    { 

      const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight) {
          setNumberSkip((prevSkip) => prevSkip + 9);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  
  return (
    <div>

      <Helmet>
        <title>Feed LeekWe | Actualités & Publications</title>
        <meta name="description" content="Explorez en temps réel les dernières publications et actualités partagées par la communauté LeekWe. Restez connecté aux contenus qui vous passionnent." />
        
        {/* Open Graph tags - Essentiels pour le partage social */}
        <meta property="og:title" content="Feed LeekWe | Actualités & Publications" />
        <meta property="og:description" content="Explorez les dernières publications de la communauté LeekWe. Contenus frais et pertinents en temps réel." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leekwe.com/feed" />
        <meta property="og:site_name" content="LeekWe" />
        
        {/* Twitter Card tags - Pour une meilleure visibilité sur Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Feed LeekWe | Contenus En Direct" />
        <meta name="twitter:description" content="Découvrez les publications fraîches de la communauté LeekWe." />
        
        {/* SEO tags critiques */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta name="keywords" content="LeekWe, feed, actualités, publications, communauté, contenu, temps réel, social" />
        <link rel="canonical" href="https://leekwe.com/feed" />
        
        {/* Tags pour performance et expérience utilisateur */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="theme-color" content="#votre-couleur" />
        <html lang="fr" />
        
        {/* Tags pour refresh dynamique - Optionnel selon votre setup */}
        <meta http-equiv="Cache-Control" content="no-cache" />
        <meta http-equiv="Pragma" content="no-cache" />
      </Helmet>

      <main>
        {token ? (

          <>

            <PublicationListUtilisateur publications={publications} provenance="PAGE_CLASSIQUE"/>

            <ModalPreferencesUtilisateur 
              changeUserPreference={changeUserPreference}
              isOpen={modalPreferencesIsOpen} 
              setIsOpen={setModalPreferencesIsOpen} 
            />


          </>
          
        ) : (

          <>
          
            <PublicationListeVisiteur publications={publications} provenance="PAGE_CLASSIQUE" />

            <ModalPreferencesUtilisateur 
              changeUserPreference={changeUserPreference}
              isOpen={modalPreferencesIsOpen} 
              setIsOpen={setModalPreferencesIsOpen} 
            />


          </>

        )}

        {isLoading && 
          <LoadingSpinner />
        }

        {asLoadingMore && 
          <LoadingSpinner />
        }

        <NotificationBubble
          updateResult={""}
          errorResult={errorPublicationSuggestion || errorPublicationPreferences}
          successMessage = ""
          navigation = ""
        />

        <button 
          onClick={() => {
            setModalPreferencesIsOpen(true);
            scrollToTop();
          }} 
          className="modal-preferences-floating-btn"
        >
                        
          <img 
            src={preferenceActive} 
            alt='Préférences'
            className='preference-icone'
          />
        
        </button>

      
      </main>
    </div>
  );


};


export default Accueil;
