import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


const STRIPE_PUBLIC_KEYS = {
  production: 'pk_live_51QE5KxFlmS1mjWO5w1vUxwzJAFa66LecDUvtbQ76uJpftMTBWVl15LiEFY8U5CjSH5Bl3HawuQXgH42FquB6LfhM00r4kcrSbD',
  preprod: 'pk_test_51QE5KxFlmS1mjWO5bqBsBXIX6tKpvwspfhWAD8igFx0bx5906YQB0GmDYK5cyjkU4335YSnpwTMAO55VkT4Wf1SV004tocW3mu'
};


const StripeLoaderLeekWe = ({ children }) => {

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {

    const hostname = window.location.hostname;
    const isProd = hostname.includes('leekwe.com');

    const publicKey = isProd 
      ? STRIPE_PUBLIC_KEYS.production 
      : STRIPE_PUBLIC_KEYS.preprod;

    if (publicKey) {
        const stripePromiseInstance = loadStripe(publicKey);
        setStripePromise(stripePromiseInstance);
    }

  }, []);

  if (!stripePromise) {
    return <div>Chargement...</div>; 
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeLoaderLeekWe;